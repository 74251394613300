import { Alert, Button, IconButton, Popover } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import LeveHeroBanner from "../../assets/img/leve-mercado-hero-banner-flat.png";
import LeveMercadoLogo from "../../assets/img/black-logo-LEVE.png";
import ClockIcon from "../../assets/img/icon-clock-checked.svg";
import ShopIcon from "../../assets/img/icon-grocery-store.svg";
import PaidIcon from "../../assets/img/icon-paid.svg";

import {
    Container,
    Header,
    Middle,
    LeveSection,
    Title,
    Subtitle,
    DownloadSection,
    WhatsappText,
    ImageSection,
    Footer,
    FooterLeft,
    BigNumber,
    FooterRight,
    Benefit,
} from "./style";
import { useEffect, useState } from "react";

export function Tablet() {
    useEffect(() => {
        const interval = setInterval(() => {
            setAnchorEl(null);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Container>
            <Header>
                <img height={48} src={LeveMercadoLogo} alt="logotipo da leve mercado" />
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: "var(--brand-green)",
                        color: "var(--white)",
                        height: 48,
                        width: 200,
                        fontWeight: "bold",
                        boxShadow: "none",
                    }}
                    href="https://api.whatsapp.com/send?1=pt_BR&phone=555120420682"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    FALE COM A GENTE
                </Button>
            </Header>
            <Middle>
                <ImageSection>
                    <img
                        src={LeveHeroBanner}
                        alt="imagem de um micro mercado autônomo mostrando uma geladeira e um móvel com produtos expostos"
                    />
                </ImageSection>
                <LeveSection>
                    <Title>Leve com você</Title>

                    <Subtitle>Tenha um Micro Mercado Autônomo dentro do seu condomínio ou empresa.</Subtitle>

                    <DownloadSection>
                        <Button
                            variant="contained"
                            startIcon={
                                <CloudDownloadIcon
                                    style={{
                                        height: 32,
                                        width: 32,
                                    }}
                                />
                            }
                            style={{
                                backgroundColor: "var(--brand-green)",
                                color: "var(--white)",
                                height: 48,
                                width: 264,
                                fontWeight: "bold",
                                gap: "0.25rem",
                                boxShadow: "none",
                            }}
                            onClick={handleClick}
                            aria-describedby={id}
                            href="/Leve-mercado-apresentacao.pdf"
                            download="Leve-Mercado-Apresentação"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Baixar Apresentação
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Alert severity="success">Apresentação baixada com sucesso!</Alert>
                        </Popover>

                        <IconButton
                            variant="square"
                            style={{
                                backgroundColor: "var(--white)",
                                border: "2px solid var(--brand-green)",
                                borderRadius: 8,
                                height: 48,
                                width: 48,
                                boxShadow: "none",
                            }}
                            href="https://api.whatsapp.com/send?1=pt_BR&phone=555120420682"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <WhatsAppIcon
                                style={{
                                    height: 32,
                                    width: 32,
                                    color: "var(--brand-green)",
                                }}
                            />
                        </IconButton>

                        <WhatsappText>
                            <p>Quer saber mais?</p>
                            <p>Nos chama no whats</p>
                        </WhatsappText>
                    </DownloadSection>
                </LeveSection>
            </Middle>
            <Footer>
                <FooterLeft>
                    <BigNumber>
                        <p>Vendas</p>
                        <p>
                            24<span>h</span>
                        </p>
                    </BigNumber>
                    <BigNumber>
                        <p>Aberto</p>
                        <p>
                            365<span>d</span>
                        </p>
                    </BigNumber>
                    <BigNumber>
                        <p>Produtos</p>
                        <p>
                            200<span>+</span>
                        </p>
                    </BigNumber>
                </FooterLeft>
                <FooterRight>
                    <Benefit>
                        <span>
                            <img height={42} src={ClockIcon} alt="ícone de um relógio com um checkmark" />
                        </span>
                        <span>Conveniência e Praticidade</span>
                    </Benefit>
                    <Benefit>
                        <span>
                            <img
                                height={42}
                                src={ShopIcon}
                                alt="ícone de um carrinho de compras dentro de uma casa"
                            />
                        </span>
                        <span>Sem Filas ou Deslocamento</span>
                    </Benefit>
                    <Benefit>
                        <span>
                            <img
                                height={42}
                                src={PaidIcon}
                                alt="ícone de um checkmark dentro de uma cestinha de compras"
                            />
                        </span>
                        <span>Compras com Autonomia</span>
                    </Benefit>
                </FooterRight>
            </Footer>
        </Container>
    );
}

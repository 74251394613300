import styled from "styled-components";

export const Container = styled.main`
    width: 100%;


    max-height: 100vh;
    height: 100vh;

    display: flex;
    flex-direction: column;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
`;

export const Middle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    padding-top: 3rem;
    gap: 3rem;
`;

export const LeveSection = styled.section`
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;

    padding: 0 0 0 4rem;
`;

export const Title = styled.h1`
    font-size: 5rem;
    line-height: 0.8;
`;

export const Subtitle = styled.h2`
    font-size: 1.25rem;
    max-width: 60%;
    font-weight: normal;
    color: var(--dark-green);
`;

export const DownloadSection = styled.div`
    display: flex;
    align-items: center;

    gap: 1rem;
`;

export const WhatsappText = styled.div`
    font-weight: normal;
    font-size: 0.75rem;

    p {
        color: var(--gray-400);
    }
`;

export const ImageSection = styled.section`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0 0 0 4rem;

    img {
        width: 45%;
    }
`;


export const Footer = styled.footer`
    padding: 2rem 0 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;    
`;

export const FooterLeft = styled.section`
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
`;

export const BigNumber = styled.span`
    p {
        color: var(--dark-green);
        &:first-child {
            font-size: 1.25rem;
            line-height: 1.3;
        }
        &:last-child {
            font-size: 3.25rem;
            font-weight: bold;
            line-height: 0.7;
            
            span {
                color: var(--brand-green);
            }
        }
    }
`;

export const FooterRight = styled.section`
    flex: 1;

    padding: 0 4rem 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.25rem;
`;

export const Benefit = styled.div`
    background-color: var(--secondary-green);
    background-color: var(--light-yellow);
    border-radius: 8px;

    padding: 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    span {
        display: flex;
        align-items: center;
        color: var(--dark-green);
    }
`;

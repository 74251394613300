import styled from "styled-components";

export const Container = styled.main`
    width: 100%;


    max-height: 100vh;
    height: 100vh;

    display: flex;
    flex-direction: column;

    padding: 0.675rem;
`;

export const Header = styled.header`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    position: relative;
    padding: 1rem 2rem;

    div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-bottom: 3rem;
        }
    }
`;

export const Middle = styled.div`
    display: flex;
    flex-direction: column;

    gap: 3rem;
`;

export const ImageSection = styled.section`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    border-radius: 24px;
    overflow: hidden;

    img {
        z-index: -2;
        height: 105%;
        translate: -10% 0;
    }
`;

export const ImageGradientFilter = styled.section`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: linear-gradient(to top, rgba(26,26,26, 0.2), rgba(26,26,26, 0.8));
`;

export const HeaderFooter = styled.section`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.675rem;
`;

export const Benefit = styled.span`
    background-color: var(--secondary-green);
    background-color: var(--light-yellow);
    background-color: var(--yellowish);
    border-radius: 8px;

    padding: 1rem 0;
    font-size: 0.675rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    span {
        display: flex;
        align-items: center;
        color: var(--dark-green);
        text-align: center;
    }
`;


export const LeveSection = styled.section`
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.75rem;

    padding: 1rem 0.25rem;
`;

export const Title = styled.h1`
    font-size: 3rem;
    line-height: 0.8;
`;

export const Subtitle = styled.h2`
    font-size: 1rem;
    max-width: 80%;
    font-weight: normal;
    color: var(--dark-green);
`;


export const BigNumbers = styled.section`
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.675rem;
`;

export const BigNumber = styled.span`
    p {
        color: var(--dark-green);
        &:first-child {
            font-size: 0.875rem;
            line-height: 1.3;
        }
        &:last-child {
            font-size: 2.5rem;
            font-weight: bold;
            line-height: 0.7;
            
            span {
                color: var(--brand-green);
            }
        }
    }
`;

export const DownloadSection = styled.div`
    display: flex;
    flex-direction: column;

    gap: 0.75rem;
    
    div {
        display: flex;
        gap: 0.75rem;
    }
`;

export const WhatsappText = styled.span`
    font-weight: normal;
    font-size: 0.75rem;

    display: flex;
    gap: 0;
    flex-direction: column;
    justify-content: center;

    p {
        color: var(--gray-400);
    }
`;

import { useEffect, useState } from "react";
import { Web } from "./components/Web";
import { Tablet } from "./components/Tablet";
import { Phone } from "./components/Phone";

const BREAKPOINTS = {
    tablet: 600,
    monitor: 992,
}

const TEMPLATES = {
    phone: <Phone />,
    tablet: <Tablet />,
    monitor: <Web />,
}

function getWindowWidth() {
    const { innerWidth } = window;
    return innerWidth;
}

export function App() {
    const [w, setW] = useState(getWindowWidth())

    useEffect(() => {
        function handleWindowResize() {
            setW(getWindowWidth());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    let template;
    
    
    if (w < BREAKPOINTS.tablet) {
        template = TEMPLATES.phone;
    } else if (w > BREAKPOINTS.tablet && w < BREAKPOINTS.monitor) {
        template = TEMPLATES.tablet;
    } else if (w > BREAKPOINTS.monitor) {
        template = TEMPLATES.monitor;
    }
    
    return template;
}

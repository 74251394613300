import styled from "styled-components";

export const Container = styled.main`
    margin: 0 auto;
    max-width: 1440px;

    max-height: 100vh;
    height: 100vh;

    display: flex;
    flex-direction: column;

    @media (max-width: 1280px) {
        max-width: 1280px;
    }
    @media (max-width: 1024px) {
        max-width: 992px;
    }
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 6rem;
    
    @media (max-width: 1280px) {
        padding: 2rem 3rem;
    }
    @media (max-width: 1024px) {
        padding: 2rem 2rem;
    }
`;

export const Middle = styled.div`
    flex: 1;
    display: flex;
`;

export const LeveSection = styled.section`
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    padding: 0 0 0 6rem;
    
    @media (max-width: 1280px) {
        padding: 0 0 0 3rem;
    }
    @media (max-width: 1024px) {
        padding: 0 0 0 2rem;
    }
`;

export const Title = styled.h1`
    font-size: 4.5rem;
    line-height: 0.8;
    
    @media (max-width: 1280px) {
        font-size: 5rem;
    }
    @media (max-width: 1024px) {
        font-size: 4rem;
    }
`;

export const Subtitle = styled.h2`
    font-size: 1.5rem;
    max-width: 70%;
    font-weight: normal;
    color: var(--dark-green);

    @media (max-width: 1280px) {
        font-size: 1.25rem;
    }
    @media (max-width: 1024px) {
        font-size: 1.125rem;
        max-width: 80%;
    }
`;

export const DownloadSection = styled.div`
    display: flex;
    align-items: center;

    gap: 1rem;
    
    @media (max-width: 1024px) {
        button {
            font-size: 0.75rem;
        }
    }
`;

export const WhatsappText = styled.div`
    font-weight: normal;
    font-size: 0.75rem;

    p {
        color: var(--gray-400);
    }
`;

export const ImageSection = styled.section`
    flex: 1;

    padding: 0 6rem 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
        width: 85%;
    }

    @media (max-width: 1280px) {
        padding: 0 3rem 0 0;

        img {
            width: 80%;
        }
    }
    @media (max-width: 1024px) {
        padding: 0 2rem 0 0;

        img {
            width: 75%;
        }
    }
`;


export const Footer = styled.footer`
    padding: 2rem 0;
    display: flex;

    @media (max-width: 1024px) {
        padding: 1rem 0 2rem 0;
    }
`;

export const FooterLeft = styled.section`
    flex: 1;

    padding: 0 0 0 6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;

    @media (max-width: 1280px) {
        padding: 0 0 0 3rem;
        gap: 2rem;
    }
    @media (max-width: 1024px) {
        padding: 0 0 0 2rem;
        gap: 1.5rem;
    }
`;

export const BigNumber = styled.span`
    p {
        &:first-child {
            font-size: 1.25rem;
            line-height: 1.3;
        }
        &:last-child {
            font-size: 3.75rem;
            font-weight: bold;
            line-height: 0.7;
            
            span {
                color: var(--brand-green);
            }
        }
    }

    @media (max-width: 1024px) {
        p {
            &:first-child {
                font-size: 1rem;
            }
            &:last-child {
                font-size: 2.75rem;
            }
        }
    }
`;

export const FooterRight = styled.section`
    flex: 1;

    padding: 0 6rem 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.25rem;

    @media (max-width: 1280px) {
        padding: 0 3rem 0 0;
        gap: 1rem;
    }

    @media (max-width: 1024px) {
        padding: 0 2rem 0 0;
        gap: 0.75rem;
    }
`;

export const Benefit = styled.div`
    background-color: var(--secondary-green);
    background-color: var(--light-yellow);
    border-radius: 8px;

    padding: 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    span {
        display: flex;
        align-items: center;
    }


    @media (max-width: 1280px) {
        font-size: 0.875rem;
        padding: 1rem;

        span {
            img {
                width: 3rem;
            }
        }
    }

    @media (max-width: 1024px) {
        font-size: 0.675rem;
        padding: 1rem;

        span {
            img {
                width: 2rem;
            }
        }
    }
`;
